import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cookies } from "react-cookie-consent";
import axios from "axios";
import { configuration } from "../helpers/config";
import Head from "next/head";
import HomeBanners from "./homebanners";
import Image from "next/image";
import FindPerfectSuzuki from "./find-suzuki/findPerfectSuzuki";
import { Constants } from "../helpers/constants";
import HomeCmsLinks from "./homeCmsLinks";
import TestimonialSliderContainer from "./testimonial/TestimonialSliderContainer";

const Homepage = (props) => {
  const [showRecentBanner, setShowRecentBanner] = useState(false);
  const [recentBannerUrl, setRecentBannerUrl] = useState("");
  const initialState = {
    titleColor: "",
    titleFontSize: "",
    descColor: "",
    descFontSize: "",
    ctaColor: "",
    ctaFontSize: "",
  };
  const [banner1ThemeData, setBanner1ThemeData] = useState(initialState);
  const [banner2ThemeData, setBanner2ThemeData] = useState(initialState);
  const [banner3ThemeData, setBanner3ThemeData] = useState(initialState);

  //For managing scroll behaviour
  useEffect(() => {
    if (typeof window !== "undefined") {
      const element = document.getElementById(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  useEffect(() => {
    // Retrieve the value of 'visitorunino' cookie
    const visitoruninoValue = Cookies.get("visitorunino");

    if (visitoruninoValue) {
      // Decode the Base64-encoded value
      const decodedValue = atob(visitoruninoValue);

      // Split the decoded value to extract the unique ID and model code
      const [uniqueId, modelCode] = decodedValue.split("_");

      modelCode && getModelBanner(modelCode);
    }
  }, []);
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
  //fetch data of model that is to be shown from cookie if user already visited that brand page.
  const getModelBanner = async (slug) => {
    if (!slug) {
      console.log("Slug is required but not provided.");
      return;
    }
  
    if (!LOB_VALUE) {
      console.log("LOB_VALUE is null or undefined.");
      return;
    }
    const { data } = await axios.get(
      `${configuration.APP_URL_PRODUCTS}model/${slug}?lob=${LOB_VALUE}`,
    );
    if (data?.data?.images) {
      setShowRecentBanner(true);
      setRecentBannerUrl(data?.data?.images?.desk_banners[0]);
    }
  };

  useEffect(() => {
    // fetch theme data
    if (!LOB_VALUE) return;
    if (LOB_VALUE) {
      getThemeData();
    }
  }, [LOB_VALUE]);

  const getThemeData = async () => {
    try{
    // fetching theme data from settings api
    const { data } = await axios.get(
      `${configuration.APP_URL_CONTENTS}/settings?lob=${LOB_VALUE}`,
    );
    // assigning theme data
    if (data?.length > 0) {
      const themeData = data
        ?.find((x) => x.lob == LOB_VALUE)
        ?.theme_settings?.find((x) => x?.applied === true);
      setBanner1ThemeData({
        ...banner1ThemeData,
        titleColor: themeData?.homepage_banner1_title_color,
        titleFontSize: themeData?.homepage_banner1_title_font_size,
        descColor: themeData?.homepage_banner1_desc_color,
        descFontSize: themeData?.homepage_banner1_desc_font_size,
        ctaColor: themeData?.homepage_banner1_cta_color,
        ctaFontSize: themeData?.homepage_banner1_cta_font_size,
      });
      setBanner2ThemeData({
        ...banner2ThemeData,
        titleColor: themeData?.homepage_banner2_title_color,
        titleFontSize: themeData?.homepage_banner2_title_font_size,
        descColor: themeData?.homepage_banner2_desc_color,
        descFontSize: themeData?.homepage_banner2_desc_font_size,
        ctaColor: themeData?.homepage_banner2_cta_color,
        ctaFontSize: themeData?.homepage_banner2_cta_font_size,
      });
      setBanner3ThemeData({
        ...banner3ThemeData,
        titleColor: themeData?.homepage_banner3_title_color,
        titleFontSize: themeData?.homepage_banner3_title_font_size,
        descColor: themeData?.homepage_banner3_desc_color,
        descFontSize: themeData?.homepage_banner3_desc_font_size,
        ctaColor: themeData?.homepage_banner3_cta_color,
        ctaFontSize: themeData?.homepage_banner3_cta_font_size,
      });
    }
  }catch (error) {
    if (error.response) {
      console.log("API Error:", error.response.data);
      console.log("Status Code:", error.response.status);
    } else if (error.request) {
      console.log("No response received from API:", error.request);
    } else {
      console.log("Error in API request:", error.message);
    }
    }
    
  };

  return (
    <>
      <Head>
      <title>{props?.data && props?.data[0]?.seo_settings?.meta_title}</title>
        <meta
          name="description"
          content={props?.data && props?.data[0]?.seo_settings?.meta_desc}
        />
        <meta
          name="keywords"
          content={props?.data && props?.data[0]?.seo_settings?.keywords}
        />
        {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
        <link rel="icon" href={`${configuration.IMG_STATIC_URL}favicon.ico`} />

      </Head>

      {props.data ? (
        <>
          {/* Top Banner section  */}
          {props.data[0]?.banners && (
            <HomeBanners
              banners={props.data[0]?.banners}
              banner1ThemeData={banner1ThemeData}
              banner2ThemeData={banner2ThemeData}
              banner3ThemeData={banner3ThemeData}
            />
          )}
          {/*show recent banner if user already visited a brand page*/}
          {showRecentBanner && (
            <div>
              <div className="mt-10">
                <Image
                  src={`${configuration.IMG_URL}${recentBannerUrl}`}
                  height={1080}
                  width={1920}
                  alt=""
                  className={"w-full"}
                />
              </div>
            </div>
          )}
          {/* find your perfect suzuki section */}
          <div id="find-perfect-suzuki">
            {props.data[0]?.cars && (
              <FindPerfectSuzuki
                sectionHeading={Constants.homePage.HOMEPAGE_SUZUKI}
                carsData={props.data[0]?.cars}
                modelsData={props?.modeldata}
              />
            )}
          </div>

          {/* cms pages interlinking cards */}
          {props.data[0]?.placeholder && (
            <HomeCmsLinks cmspages={props.data[0]?.placeholder} />
          )}
          <div>
            <TestimonialSliderContainer />
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-screen">
          {/* Data not found Text if data not available from API */}
          <h1>{Constants.generic.DATA_NOT_FOUND}</h1>
        </div>
      )}
    </>
  );
};

export default Homepage;
