import { configuration } from "../helpers/config";
import CarsPage from './cars';
import {homePageFetcher} from "../utils/GenUtils";
import {MenuFunction,FooterFunction} from '../utils/menu';
import store from '../redux/store';
import {FetchModel} from '../utils/apiCall';

export async function getServerSideProps() {
  // fetching homepage and cms-page data and pairing the data id respective ids.
  const { dispatch, getState } = store;
  const LOB_VALUE = getState().app.LobValue;
  const Menudata = await MenuFunction();
  const Footerdata = await FooterFunction();
  const modeldata = await FetchModel(LOB_VALUE);
  let props = {
    // isLobDefault: false,
    data: {},
    error: false,
    Menudata: Menudata,
    Footerdata: Footerdata,
    modeldata:modeldata,
  };
  await  homePageFetcher(props, configuration.COMMON_MASTER_LOB);
  return { props };
}
export default function Main(props) {
  return (
    <>
      {/* <LobPage {...props.data} /> */}
      <CarsPage {...props} />
    </>
  );
}
