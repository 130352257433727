import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import TestimonialSlider from "./TestimonialSlider";
import axios from "axios";
import { configuration } from "../../helpers/config";
import { toast } from "react-toastify";
import { Constants } from "../../helpers/constants";

const TestimonialSliderContainer = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const LOB_VALUE=useSelector(state=>state?.app?.LobValue);
 
  useEffect(() => {
    if (!LOB_VALUE) return; 
    (async () => {
      try {
        const res = await axios.get(
          `${configuration.APP_URL_CONTENTS}/testimonial?lob=${LOB_VALUE}`,
        );
        setTestimonialData(res.data);
      } catch (e) {
        console.log(e.message);
        // toast.error(Constants.testimonial.ERROR_FETCHING);
      }
    })();
  }, [LOB_VALUE]);

  return (
    <div>
      {testimonialData && testimonialData?.length > 0 && (
        <TestimonialSlider showHeading={true} data={testimonialData} />
      )}
    </div>
  );
};

export default TestimonialSliderContainer;
