import React, {useEffect} from "react";
import axios from "axios";
import { configuration } from "../../helpers/config";
import Homepage from "../../components/Homepage";
import {homePageFetcher} from "../../utils/GenUtils";
import {useDispatch} from "react-redux";
import {UPDATE_LOB_VALUE} from "../../redux/ActionTypes";
import { MenuFunction, FooterFunction } from "../../utils/menu";

export async function getServerSideProps() {
  // fetching homepage and cms-page data and pairing the data id respective ids.
  const Menudata = await MenuFunction();
  const Footerdata = await FooterFunction();
  let props = {
    isLobDefault: false,
    data: {},
    error: false,
    Menudata:Menudata,
    Footerdata:Footerdata,
  };

  await  homePageFetcher(props, configuration.COMMON_MASTER_LOB);

  return { props };
}
export default function CarHomePage(props) {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({type: UPDATE_LOB_VALUE, payload: configuration.COMMON_MASTER_LOB})
  },[])

  return (
    <div>
      <Homepage {...props} />
    </div>
  );
}
